import React, { useMemo, useState } from 'react';
import Card from '../common/Card';
import Avatar from '../common/Avatar';
import location from '../../assets/images/location.svg';
import mail from '../../assets/images/mail.svg';
import contact from '../../assets/images/contact.svg';
import link from '../../assets/images/link.svg';
import globe from '../../assets/images/Globe.svg';
import edit from '../../assets/images/editIcon.svg';
import ThreeDots from '../Icons/ThreeDots';
import Dropdown from '../common/Dropdown';
import OutlinedButton from '../common/OutlinedButton';
import Modal from '../../components/Modal';
import EditProfile from '../../components/ProfilePage/EditProfile';
import { useDispatch } from 'react-redux';
import {
  blockUserDispatcher,
  followOtherUserDispatcher,
  unfollowOtherUserDispatcher,
} from '../../redux/dispatchers/otherUserDispatcher';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import ConfirmationModal from '../Modal/ConfirmationModal';
import ReportUser from '../Post/ReportUser';

const { HOME, MESSAGES } = PATHS;

const ProfileContainer = ({
  userData,
  isOtherUser = false,
  reloadAfterFollowUnfollow = () => {},
}) => {
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [isLoadingFollowUnfollow, setIsLoadingFollowUnfollow] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const followUnfollowHandler = async () => {
    if (isLoadingFollowUnfollow) {
      return;
    }

    let response;
    setIsLoadingFollowUnfollow(true);
    if (userData?.followStatus?.isApproved || userData?.followStatus?.isApproved === false) {
      response = (await dispatch(unfollowOtherUserDispatcher({ id: userData?.id }))) || {};
    } else if (userData?.followStatus?.isApproved === undefined) {
      response = (await dispatch(followOtherUserDispatcher({ id: userData?.id }))) || {};
    }

    if (response) {
      const { status, data } = response;

      if (successStatus(status)) {
        // Means that this request which was sent has been removed
        if (userData?.followStatus?.isApproved === false) {
          ToastNotifySuccess('Sent request has been cancelled');
        } else if (!data?.data?.isApproved) {
          ToastNotifySuccess('A follow request has been sent');
        }
        await reloadAfterFollowUnfollow();
      } else {
        const errormsg = getErrorMessage(data);
        if (errormsg) {
          ToastNotifyError(errormsg);
        }
      }
    }
    setIsLoadingFollowUnfollow(false);
  };

  const memoizedAvatar = useMemo(
    () => (
      <Avatar
        classNames="w-[40%] h-[40%] max-w-[100px] max-h-[100px] m-auto"
        image={userData?.profilePictureUrl}
        name={`${userData?.firstName} ${userData?.lastName}`}
      />
    ),
    [userData?.id],
  );

  const followUnfollowLabel = () => {
    if (userData?.followStatus?.isApproved) {
      return 'Following';
    } else if (userData?.followStatus?.isApproved === false) {
      return 'Request Sent';
    } else {
      return 'Follow';
    }
  };

  const blockClickHandler = async () => {
    const { status, data } = await dispatch(
      blockUserDispatcher({ userId: userData?.id, showLoader: true }),
    );

    if (successStatus(status)) {
      ToastNotifySuccess('The user has been blocked');
      navigate(HOME, { replace: true });
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    }
  };
  const redirectMessageHandler = (user) => {
    navigate(MESSAGES);
    const jsonString = JSON.stringify(user);
    localStorage.setItem('redirectionData', jsonString);
  };
  return (
    <Card classNames="lg:block py-4 px-2 md:px-4 relative">
      <div className="block gap-4">
        {!isOtherUser ? (
          <div
            className="bg-iconBackground p-1 rounded w-fit absolute right-[5%]"
            onClick={() => setIsEditingModalOpen(true)}
          >
            <img src={edit} alt="edit" className="cursor-pointer" />
          </div>
        ) : (
          <div className="p-1 rounded w-fit absolute right-[5%]">
            <Dropdown
              IconComponent={ThreeDots}
              options={[
                { name: 'Report', action: () => setIsReportModalOpen(true) },
                { name: 'Block', action: () => setIsBlockModalOpen(true) },
              ]}
            />
          </div>
        )}

        {memoizedAvatar}
        <div className="flex flex-col gap-1 overflow-hidden text-center pt-2">
          <p className="text-greydark text-[14px] md:text-[20px] font-semibold overflow-hidden truncate capitalize">
            {userData?.firstName} {userData?.lastName}
          </p>
          <div className="text-[#333] text-[14px] font-normal">
            {userData?.tagline?.description}
          </div>
          {userData?.bio && userData?.bio?.description ? (
            <div className=" bg-[#F7F7F7] rounded-[4px] p-[8px] max-h-[calc(100vh-530px)] overflow-y-auto max">
              <div className=" text-left text-[#333] text-[12px] font-semibold">Bio</div>
              <div className=" text-justify text-[#727272] text-[10px] font-medium">
                {userData?.bio?.description}
              </div>
            </div>
          ) : (
            ''
          )}
          {userData?.designation ? (
            <h4 className="font-normal text-greydark text-[12px] md:text-[14px]">
              {userData?.designation}
            </h4>
          ) : (
            ''
          )}
          <div className="flex flex-col text-left items-start gap-y-[12px]">
            {userData?.email ? (
              <div className="flex font-medium text-greydark text-[10px] justify-center items-center">
                <img src={mail} alt="mail" className="pr-1" />
                {userData?.email}
              </div>
            ) : (
              ''
            )}
            {userData?.contactPhoneNumbers &&
            userData?.contactPhoneNumbers?.length !== 0 &&
            userData?.contactPhoneNumbers[0]?.phoneNumber ? (
              <div className="flex font-medium text-greydark text-[10px] justify-center items-center">
                <img src={contact} alt="location" className="pr-1" />
                {'+' + userData?.contactPhoneNumbers[0]?.phoneNumber}
              </div>
            ) : (
              ''
            )}
            {userData?.UserActiveLocation && userData?.UserActiveLocation?.length !== 0 ? (
              <div className="flex font-medium text-greydark text-[10px] justify-center items-center">
                <img src={location} alt="location" className="pr-1" />
                {(userData?.UserActiveLocation[0]?.city
                  ? userData?.UserActiveLocation[0]?.city + ', '
                  : '') +
                  (userData?.UserActiveLocation[0]?.state
                    ? userData?.UserActiveLocation[0]?.state + ', '
                    : '') +
                  (userData?.UserActiveLocation[0]?.country
                    ? userData?.UserActiveLocation[0]?.country
                    : '')}
              </div>
            ) : (
              ''
            )}
            {userData?.websiteLink ? (
              <div className="flex font-medium text-greydark text-[10px] justify-center items-center break-all">
                <img src={globe} alt="mail" className="pr-1" />
                {userData?.websiteLink}
              </div>
            ) : (
              ''
            )}
            {userData?.socialMediaLink ? (
              <div className="flex font-medium text-greydark text-[10px] justify-center items-center break-all">
                <img src={link} alt="mail" className="pr-1" />
                {userData?.socialMediaLink}
              </div>
            ) : (
              ''
            )}
            {userData?.contactEmails && userData?.contactEmails?.length !== 0 ? (
              <div className="flex font-medium text-greydark text-[10px] justify-center items-center">
                <img src={mail} alt="mail" className="pr-1" />
                {userData?.contactEmails[0]}
              </div>
            ) : (
              ''
            )}
          </div>
          {isOtherUser && (
            <div className="flex gap-[7%] justify-center mt-2">
              {userData && 'followStatus' in userData ? (
                !userData?.PrivacySettings?.[0]?.chatSetting === 'chatFollowership' ||
                (userData?.PrivacySettings?.[0]?.chatSetting === 'chatConnection' &&
                  !userData.followStatus?.isConnection) ? (
                  ''
                ) : (
                  <OutlinedButton
                    label={'Message'}
                    additionalClassNames="!text-[14px]"
                    onClick={() => redirectMessageHandler(userData)}
                  />
                )
              ) : userData?.PrivacySettings?.[0]?.chatSetting === 'chatAnyone' ? (
                <OutlinedButton
                  label={'Message'}
                  additionalClassNames="!text-[14px]"
                  onClick={() => redirectMessageHandler(userData)}
                />
              ) : (
                ''
              )}

              <OutlinedButton
                onClick={() => followUnfollowHandler()}
                label={followUnfollowLabel()}
                additionalClassNames="!bg-blueprimary text-white !text-[14px] w-[135px] !justify-center !px-1"
                isLoading={isLoadingFollowUnfollow}
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isEditingModalOpen}
        onClose={() => setIsEditingModalOpen(false)}
        isTitle={true}
        title={'Edit Profile'}
        childrenClassNames=""
        padding="!p-0"
        width="w-[80vw] max-w-[1030px]"
        titleClassNames=""
        titleParentClassNames="my-[20px]"
        height="md:h-auto"
      >
        <EditProfile
          {...userData}
          newBio={userData?.aboutMyself}
          onClose={() => setIsEditingModalOpen(false)}
        />
      </Modal>

      <ConfirmationModal
        title="Block User"
        isOpen={isBlockModalOpen}
        onClose={() => setIsBlockModalOpen(false)}
        primaryButtonTitle="No"
        primaryButtonAction={() => setIsBlockModalOpen(false)}
        secondaryButtonTitle="Yes"
        secondaryButtonAction={() => blockClickHandler()}
      >
        <div className="text-[18px] tx-greydark font-medium">
          Are you sure you want to block <br />“{userData?.firstName} {userData?.lastName}
          ”?
        </div>
      </ConfirmationModal>

      <ReportUser
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        userId={userData?.id}
      />
    </Card>
  );
};

export default ProfileContainer;
