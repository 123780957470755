import React, { useEffect, useState, useRef } from 'react';
import downArrow from '../../assets/images/downArrow.svg';

const AutoComplete = ({
  options = [],
  onChange = () => {},
  defaultValue = '',
  parentClassNames = '',
  isRequired = false,
  labelFontColor = '#ffffff',
  placeholder = 'Select Options',
  label = '',
  maxLength,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (autoCompleteRef.current && !autoCompleteRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const filteredOptions = options?.filter((option) =>
  // option?.toLowerCase()?.includes(inputValue?.toLowerCase()),
  // );

  const handleInputChange = (e) => {
    setInputValue(e?.target?.value);
    setIsOpen(true);
    onChange(e?.target?.value.trim());
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div className={`${parentClassNames} relative width-[100%]`} ref={autoCompleteRef}>
      <div className="flex gap-[2px]">
        <label style={{ color: labelFontColor }}>{label}</label>
        {isRequired ? <span className="text-red relative top-[-4px]">*</span> : ''}
      </div>
      <div className="flex items-center w-full rounded-lg bg-white pr-[10px] justify-between border border-lightgrey">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          onClick={() => setIsOpen(!isOpen)}
          className="w-full py-[15px]"
          maxLength={maxLength}
        />
        <img
          src={downArrow}
          alt="Down Arrow"
          className="ml-2 w-6 h-6 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>

      {isOpen && (
        <div
          className={`${
            options.length > 0
              ? 'absolute z-10 mt-1 w-48 bg-white border rounded-md shadow-lg'
              : 'hidden'
          } w-full rounded-lg border border-lightgrey bg-white`}
        >
          {options?.map((option) => (
            <div
              key={option}
              onClick={() => handleOptionClick(option)}
              className="py-2 px-4 cursor-pointer hover:bg-gray-100"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
