import React, { useEffect, useRef, useState } from 'react';
import SectionLayout from '../../components/PrivateLayout/SectionLayout';
import SearchIcon from '../../assets/images/searchIcon.svg';
import cross from '../../assets/images/cross.svg';
import noMessage from '../../assets/images/No-Messages.svg';
import messageVector from '../../assets/images/Message-vector.svg';
import Avatar from '../../components/common/Avatar';
import TextSeen from '../../components/Icons/TestSeen';
import Chats from './chats';
import ThreeDots from '../../components/Icons/ThreeDots';
import Dropdown from '../../components/common/Dropdown';
import BackAvatar from '../../assets/images/Back-vector.svg';
import InputTextarea from './InputTextarea';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import db from '../../firebase';
import { AllUsers } from '../../services/messageService';
import ReportUser from '../../components/Post/ReportUser';
import TimeAgo from './TimeAgo';
import { useDispatch, useSelector } from 'react-redux';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import {
  blockUserDispatcher,
  unblockUserDispatcher,
} from '../../redux/dispatchers/otherUserDispatcher';
import { getErrorMessage, successStatus } from '../../common';
import AttachmentIcon from '../../components/Icons/AttachmentIcon';
import { globalTransparentLoadingPrivate, updateUnreadCount } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';

const Messages = () => {
  const [allFollowers, setAllFollowers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [searchedFollwers, setSearchedFollwers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [addContact, setAddContact] = useState(true);
  const [isActive, setIsActive] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [originalSearchedFollowers, setOriginalSearchedFollowers] = useState([]);
  const [toId, setToId] = useState('');
  const [newMessage, setNewMessage] = useState(false);
  const [retrievedDocumentId, setRetrievedDocumentId] = useState('');
  const [mobileChat, setMobileChats] = useState(false);
  const [selected, setSelected] = useState([]);
  const [originalContacts, setOriginalContacts] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [userId, setUserId] = useState('');
  const [clearInput, setClearInput] = useState('');
  const [attachmentProgress, setAttachmentProgress] = useState('');
  const [isBlocking, setIsBlocking] = useState(false);
  const [mediaLength, setMediaLength] = useState(0);
  const myProfile = useSelector((state) => state.auth.user);
  const chatContainerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNavigated, setPageNavigated] = useState(false);

  const checkContacts = () => {
    const checknav = localStorage?.getItem('nav');
    if (checknav) {
      setPageNavigated(true);
      localStorage.removeItem('nav');
    }
  };

  const dbName = process.env.REACT_APP_ENV === 'dev' ? 'test_messages' : 'uat_messages';
  const fetchFollowersList = async () => {
    let allowedContacts = [];
    const { status, data } = await AllUsers();
    if (status) {
      let allData = data?.data?.Networks;
      allData.map((element) => {
        if (
          element?.User?.PrivacySettings[0]?.chatSetting === 'chatAnyone' ||
          element?.FollowingUser?.PrivacySettings[0]?.chatSetting === 'chatAnyone'
        ) {
          allowedContacts.push(element?.User);
        } else if (
          (element?.User?.PrivacySettings[0]?.chatSetting === 'chatConnection' &&
            element?.User?.PrivacySettings[0]?.isConnection) ||
          (element?.FollowingUser?.PrivacySettings[0]?.chatSetting === 'chatConnection' &&
            element?.FollowingUser?.PrivacySettings[0]?.isConnection)
        ) {
          allowedContacts.push(element?.User);
        } else {
          allowedContacts.push(element?.User);
        }
      });

      setAllFollowers(allowedContacts);
      const newFollowers = allowedContacts.filter((newFollower) => {
        const followerId = newFollower?.username;
        const isDuplicate = contacts.some((contact) => {
          let userIndex = contact?.userIds?.findIndex((user) => {
            return user !== myProfile?.id;
          });
          const contactUsername =
            contact?.username ||
            contact?.FollowingUser?.username ||
            contact?.userDetails[userIndex]?.username;
          return contactUsername === followerId;
        });
        return !isDuplicate;
      });
      setSearchedFollwers(newFollowers);
      setOriginalSearchedFollowers(newFollowers);
    }
  };
  useEffect(() => {
    const numberOfUnreadMessages =
      contacts?.filter(
        (item) =>
          item?.lastMessage?.content !== '' &&
          item?.read === false &&
          item?.lastMessage?.idFrom !== myProfile?.id,
      )?.length ?? 0;
    localStorage.setItem('unreadMessagesCount', numberOfUnreadMessages.toString());
  }, [newMessage, dispatch]);

  const handleMediaLengthChange = (length) => {
    setMediaLength(length);
  };

  const scrollChatContainer = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  const handleCrossIconClick = () => {
    const timestampInMilliseconds = Date.now();
    setClearInput(timestampInMilliseconds);
  };
  const handleProgress = (progress) => {
    setAttachmentProgress(progress);
  };
  useEffect(() => {
    if (isActive !== null) {
      setMobileChats(true);
    }
  }, [isActive]);

  const handleFileInputChange = (selectedFile) => {
    setFileData(selectedFile);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.trim() === '') {
      setContacts([...originalContacts]);
    } else {
      const filteredContacts = contacts.filter((element) => {
        const searchItem =
          element?.firstName?.toLowerCase() ||
          otherUser(element?.userDetails).firstName.toLowerCase();
        return searchItem.includes(query.toLowerCase());
      });
      setContacts(filteredContacts);
    }
  };
  const handleFollowersSearch = (query) => {
    setSearchQuery(query);
    if (query.trim() === '') {
      setSearchedFollwers([...originalSearchedFollowers]);
    } else {
      const filteredFollowers = originalSearchedFollowers.filter((follower) =>
        follower?.firstName.toLowerCase().includes(query.toLowerCase()),
      );
      setSearchedFollwers(filteredFollowers);
    }
  };
  const clearSearch = () => {
    setSearchQuery('');
    setContacts([...originalContacts]);
    setSearchedFollwers([...originalSearchedFollowers]);
  };

  const handleSelected = (element) => {
    setSelectedElement(element);
    const otherusername = element?.lastMessage
      ? otherUser(element?.userDetails).username
      : element?.username;
    setIsActive((prevName) => (prevName === otherusername ? null : otherusername));
    setAddContact(true);
    const selectedFollower =
      allFollowers.find((follower) => follower?.username === element?.username) ||
      contacts.find(
        (contact) =>
          otherUser(contact?.userDetails)?.username === element?.username ||
          otherUser(contact?.userDetails).username === otherUser(element?.userDetails).username,
      ) ||
      element;
    setSelected(selectedFollower);
    const isAlreadyAdded = contacts.some(
      (contact) =>
        contact?.username === selectedFollower?.username ||
        otherUser(contact?.userDetails)?.username === selectedFollower?.username,
    );
    if (!isAlreadyAdded) {
      if (!pageNavigated) {
        setContacts((prevContacts) => {
          const updatedContacts = [...prevContacts, selectedFollower];
          setIsActive(selectedFollower?.username);
          return updatedContacts;
        });
        setSearchedFollwers((prevFollowers) =>
          prevFollowers.filter((follower) => follower?.id !== selectedFollower?.id),
        );
      }
    }
    if (selectedFollower) {
      let newToId;
      if (!selectedFollower.lastMessage) {
        newToId = selectedFollower?.id;
        setToId(newToId);
      } else {
        const userIds = selectedFollower?.userIds;
        newToId = userIds.find((element) => element !== myProfile.id);
        setToId(newToId);
      }
      let collectionId;
      const numericToId = parseInt(newToId, 10);
      const numericMyProfileId = parseInt(myProfile?.id, 10);

      if (numericToId < numericMyProfileId) {
        collectionId = `${numericToId}_${numericMyProfileId}`;
      } else {
        collectionId = `${numericMyProfileId}_${numericToId}`;
      }

      setRetrievedDocumentId((prevId) => (prevId !== collectionId ? collectionId : prevId));
    }
  };
  const truncateName = (fullName) => {
    return fullName.length > 18 ? fullName.substring(0, 18) + '...' : fullName;
  };

  const otherUser = (users) => {
    return users?.find((element) => element?.id !== myProfile?.id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let prevContacts;
        const q = query(collection(db, dbName), orderBy('timestamp'));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let messagesData = [];
          querySnapshot.forEach((doc) => {
            messagesData.push({ ...doc.data() });
          });
          const filteredMessages = messagesData.filter((element) => {
            return element?.userIds?.includes(myProfile?.id);
          });
          const sortedContacts = filteredMessages.sort((a, b) => {
            const timestampA = a.lastMessage ? a.lastMessage.timestamp : 0;
            const timestampB = b.lastMessage ? b.lastMessage.timestamp : 0;
            return timestampB - timestampA;
          });
          setNewMessage(Math.random());
          setContacts(sortedContacts);
          setContacts([...filteredMessages]);
          setOriginalContacts([...filteredMessages]);
          prevContacts = [...filteredMessages];
          checkContacts();
          const numberOfUnreadMessages =
            prevContacts?.filter(
              (item) =>
                item?.lastMessage?.content !== '' &&
                item?.read === false &&
                item?.lastMessage?.idFrom !== myProfile?.id,
            )?.length ?? 0;
          dispatch(updateUnreadCount(numberOfUnreadMessages));
          localStorage.setItem('unreadMessagesCount', numberOfUnreadMessages?.toString());
          const storedData = localStorage.getItem('redirectionData');
          if (storedData) {
            const userData = JSON.parse(storedData);
            const RedirectUser = userData;
            if (RedirectUser) {
              if (
                !prevContacts.some(
                  (element) =>
                    element?.username === RedirectUser.username ||
                    otherUser(element.userDetails).username === RedirectUser.username,
                )
              ) {
                handleSelected(RedirectUser);
              } else {
                setSelected(RedirectUser);
                setIsActive(RedirectUser?.username);
                let newToId;
                if (!RedirectUser.lastMessage) {
                  newToId = RedirectUser?.id;
                  setToId(newToId);
                } else {
                  const userIds = RedirectUser?.userIds;
                  newToId = userIds.find((element) => element !== myProfile.id);
                  setToId(newToId);
                }
                let collectionId;
                const numericToId = parseInt(newToId, 10);
                const numericMyProfileId = parseInt(myProfile?.id, 10);

                if (numericToId < numericMyProfileId) {
                  collectionId = `${numericToId}_${numericMyProfileId}`;
                } else {
                  collectionId = `${numericMyProfileId}_${numericToId}`;
                }

                setRetrievedDocumentId((prevId) =>
                  prevId !== collectionId ? collectionId : prevId,
                );
              }
            }
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();

    dispatch(globalTransparentLoadingPrivate(true));
  }, []);

  useEffect(() => {
    fetchFollowersList();
  }, [contacts]);

  useEffect(() => {
    if (isActive && contacts[0]?.lastMessage?.idFrom !== myProfile?.id) {
      updateRead();
    }
  }, [selectedElement, newMessage]);
  useEffect(() => {
    unreadMessageCount();
  }, [newMessage]);

  const updateRead = async () => {
    let selectedCollectionId = selectedElement?.chatId || contacts[0]?.chatId;
    try {
      const subcollectionRef = collection(db, dbName, selectedCollectionId, selectedCollectionId);
      const allDocsQuerySnapshot = await getDocs(subcollectionRef);
      const updatePromises = [];
      allDocsQuerySnapshot.forEach((subcollectionDoc) => {
        const subcollectionDocRef = doc(subcollectionRef, subcollectionDoc.id);
        updatePromises.push(updateDoc(subcollectionDocRef, { read: true }));
      });
      const mainDocRef = doc(db, dbName, selectedCollectionId);
      updatePromises.push(updateDoc(mainDocRef, { read: true }));

      await Promise.all(updatePromises);
    } catch (error) {
      console.warn('Error updating read status for messages: ', error);
    }
  };
  const unreadMessageCount = async () => {
    let selectedCollectionId = selectedElement?.chatId || contacts[0]?.chatId;
    try {
      const subcollectionRef = collection(db, dbName, selectedCollectionId, selectedCollectionId);
      let unreadCount = 0;
      const allDocsQuerySnapshot = await getDocs(subcollectionRef);
      allDocsQuerySnapshot.forEach((subcollectionDoc) => {
        const isRead = subcollectionDoc?.data()?.read || false;
        if (!isRead) {
          unreadCount++;
        }
      });
      const mainDocRef = doc(db, dbName, selectedCollectionId);
      await updateDoc(mainDocRef, { unReadCount: unreadCount });

      return unreadCount;
    } catch (error) {
      console.warn('Error counting unread messages: ', error);
      return 0;
    }
  };
  const updateBlock = async () => {
    let selectedCollectionId = selectedElement?.chatId || contacts[0]?.chatId;
    const documentRef = doc(db, dbName, selectedCollectionId);
    try {
      await updateDoc(documentRef, {
        'blockUser.blocked': true,
        'blockUser.blockedById': myProfile?.id,
        'blockUser.blockedId': toId,
      });
    } catch (error) {
      console.warn('Error getting documents from subcollection: ', error);
    }
  };
  const updateUnblock = async () => {
    let selectedCollectionId = selectedElement?.chatId || contacts[0]?.chatId;
    const documentRef = doc(db, dbName, selectedCollectionId);
    try {
      await updateDoc(documentRef, {
        'blockUser.blocked': false,
        'blockUser.blockedById': myProfile?.id,
        'blockUser.blockedId': toId,
      });
    } catch (error) {
      console.warn('Error getting documents from subcollection: ', error);
    }
  };
  const deleteChat = async () => {
    setIsDeleteModalOpen(false);
    const subcollectionRef = collection(db, dbName, retrievedDocumentId, retrievedDocumentId);
    const documentRef = doc(db, dbName, retrievedDocumentId);
    try {
      await updateDoc(documentRef, { 'lastMessage.content': '', 'lastMessage.timestamp': '' });
      const allDocsQuerySnapshot = await getDocs(subcollectionRef);
      allDocsQuerySnapshot.forEach(async (doc) => {
        deleteDoc(doc.ref)
          .then(() => {
            ToastNotifySuccess('Chat deleted');
          })
          .catch((error) => {
            console.warn(`Error deleting document ${doc?.id}: `, error);
          });
      });
    } catch (error) {
      console.warn('Error getting documents from subcollection: ', error);
    }
  };
  useEffect(() => {
    let ChatId = retrievedDocumentId?.split('_');
    let firstPart = ChatId[0];
    let secondPart = ChatId[1];
    let userId = '';
    if (firstPart !== myProfile?.id) {
      userId = firstPart;
    } else if (secondPart !== myProfile?.id) {
      userId = secondPart;
    }
    setUserId(userId);
  }, [retrievedDocumentId]);

  const blockClickHandler = async () => {
    if (isBlocking) {
      return;
    }
    try {
      setIsBlocking(true);

      const { status, data } = await dispatch(
        blockUserDispatcher({ userId: userId, showLoader: true }),
      );

      if (successStatus(status)) {
        ToastNotifySuccess('The user has been blocked');
        setIsBlockModalOpen(false);
        updateBlock();
      } else {
        const errormsg = getErrorMessage(data);
        setIsBlockModalOpen(false);
        if (errormsg) {
          updateUnblock();
          UnBlockClickHandler();
        }
      }
    } finally {
      setIsBlocking(false);
    }
  };
  const UnBlockClickHandler = async () => {
    if (isBlocking) {
      return;
    }
    try {
      setIsBlocking(true);
      const { status } = await dispatch(
        unblockUserDispatcher({ userId: userId, showLoader: true }),
      );

      if (successStatus(status)) {
        ToastNotifySuccess('The user has been Unblocked');
      }
    } finally {
      setIsBlocking(false);
    }
  };

  return (
    <SectionLayout activeTab={2}>
      <div className="relative flex justify-between bg-white rounded-lg shadow-card lg:mt-4 h-[89vh] md:h-[calc(100vh-120px)] overflow-hidden ">
        <div
          className={`border-r-2 border-lightgrey w-full md:w-4/12 overflow-y-auto scrollbar-custom md:block ${
            mobileChat ? 'hidden' : 'block'
          } `}
        >
          <div>
            <div className=" text-[20px] font-semibold flex justify-between m-4">
              {addContact ? <span>Messages</span> : <span>New Chat</span>}
              <span
                className={
                  addContact
                    ? 'inline-flex items-center justify-center bg-gradient-to-r from-buttongradientfrom to-buttongradientto h-8 w-8 rounded-full cursor-pointer'
                    : 'inline-flex items-center justify-center cursor-pointer'
                }
              >
                {addContact ? (
                  <span
                    className="text-white"
                    onClick={() => {
                      setAddContact(false);
                      setIsActive(null);
                      setSelectedElement(null);
                    }}
                  >
                    +
                  </span>
                ) : (
                  <img
                    src={cross}
                    alt=""
                    onClick={() => {
                      setAddContact(true);
                      setIsActive(null);
                      setSelectedElement(null);
                    }}
                  />
                )}
              </span>
            </div>
            <div className=" md:mt-6 md:mb-4 relative flex items-center border-b border-lightgrey m-4 mb-2 mt-2">
              <img src={SearchIcon} alt="" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => {
                  addContact ? handleSearch(e.target.value) : handleFollowersSearch(e.target.value);
                }}
                className="py-1 pr-3 outline-none focus:border-blueprimary transition-all duration-300 w-full"
              />
              {searchQuery && (
                <button
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={clearSearch}
                >
                  <img src={cross} alt="" />
                </button>
              )}
            </div>
            <div className="items-center w-full md:mt-6">
              {(addContact ? contacts : searchedFollwers).map((element, index) => {
                return (
                  <div
                    key={index}
                    className={` box-border border-l-[6px] border-white cursor-pointer  w-full ${
                      element.lastMessage
                        ? isActive === otherUser(element?.userDetails)?.username
                          ? 'active-message-left-side-bar bg-lightbluebg'
                          : ''
                        : isActive === element?.username
                          ? 'active-message-left-side-bar bg-lightbluebg'
                          : ''
                    }`}
                    onClick={() => handleSelected(element)}
                  >
                    <div className="border-b border-b-lightgrey w-[95%] flex items-center py-3 ml-[3px] ">
                      <div>
                        <Avatar
                          name={
                            element?.lastMessage
                              ? otherUser(element?.userDetails)?.firstName
                              : element?.firstName
                          }
                          image={
                            element?.lastMessage && otherUser(element?.userDetails)
                              ? otherUser(element?.userDetails)?.profilePictureUrl ||
                                otherUser(element?.userDetails)?.profilePicture
                              : element?.profilePictureUrl || element?.profilePicture
                          }
                          classNames="w-[40px] h-[40px]"
                        />
                      </div>
                      {addContact ? (
                        <div className="ml-2 w-full mr-2">
                          <div className="flex justify-between">
                            <h3 className="text-[16px] font-semibold">
                              {element?.lastMessage
                                ? truncateName(
                                    otherUser(element?.userDetails)?.firstName +
                                      ' ' +
                                      otherUser(element?.userDetails)?.lastName,
                                  )
                                : truncateName(element?.firstName + ' ' + element?.lastName)}
                            </h3>
                            <span
                              className={`${
                                element?.lastMessage?.content || element?.lastMessage?.subType
                                  ? 'block'
                                  : 'hidden'
                              }`}
                            >
                              <TimeAgo timestamp={element?.lastMessage?.timestamp} />
                            </span>
                          </div>
                          <div
                            className={`flex justify-between ${
                              element?.lastMessage?.content || element?.lastMessage?.subType
                                ? 'block'
                                : 'hidden'
                            }`}
                          >
                            <p
                              className={`text-[14px] font-medium text-greydark ${
                                element?.unReadCount > 0 ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {element?.lastMessage?.subType ? (
                                <div className="flex">
                                  <AttachmentIcon />
                                  <span>Attachment</span>
                                </div>
                              ) : element?.lastMessage?.content ? (
                                element?.lastMessage?.content?.length > 18 ? (
                                  `${element?.lastMessage?.content.substring(0, 10)}...`
                                ) : (
                                  element?.lastMessage?.content
                                )
                              ) : (
                                ''
                              )}
                            </p>
                            {element?.unReadCount > 0 ? (
                              element?.lastMessage?.idFrom !== myProfile?.id ? (
                                <span className="inline-flex items-center justify-center bg-gradient-to-r from-buttongradientfrom to-buttongradientto h-4 w-4 rounded-full">
                                  <span className="text-white text-[12px]">
                                    {element?.unReadCount}
                                  </span>
                                </span>
                              ) : (
                                <TextSeen seen={element?.read} />
                              )
                            ) : (
                              <TextSeen seen={element?.read} />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="ml-2 mr-2 w-full">
                          <div className="flex justify-between items-center">
                            <h3 className="text-[16px] font-semibold">
                              {truncateName(element?.firstName + ' ' + element?.lastName)}
                            </h3>
                            <img src={messageVector} alt="" className="w-[24px] h-[24px]" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={`relative md:w-8/12 overflow-hidden md:block w-full h-[89vh] md:h-full ${
            mobileChat ? 'block' : 'hidden'
          }`}
        >
          {isActive !== null ? (
            <div className="flex flex-col justify-between h-[86vh] md:h-full ">
              <div className="flex justify-between items-center border-b-2 border-whitelight w-full h-fit p-4 cursor-pointer">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate(`${PATHS.OTHER_USER_PROFILE}${userId}`)}
                >
                  <img
                    src={BackAvatar}
                    alt=""
                    className="block md:hidden cursor-pointer"
                    onClick={() => {
                      setMobileChats(false);
                      setIsActive(null);
                      setSelectedElement(null);
                    }}
                  />
                  <Avatar
                    name={
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails)?.username === isActive
                        );
                      })?.lastMessage
                        ? otherUser(
                            contacts.find((element) => {
                              return (
                                element?.username === isActive ||
                                otherUser(element?.userDetails)?.username === isActive
                              );
                            })?.userDetails,
                          )?.firstName
                        : contacts.find((element) => {
                            return (
                              element?.username === isActive ||
                              otherUser(element?.userDetails)?.username === isActive
                            );
                          })?.firstName
                    }
                    image={
                      contacts.find((element) => {
                        return (
                          element?.username === isActive ||
                          otherUser(element?.userDetails)?.username === isActive
                        );
                      })?.lastMessage &&
                      otherUser(
                        contacts.find((element) => {
                          return (
                            element?.username === isActive ||
                            otherUser(element?.userDetails)?.username === isActive
                          );
                        })?.userDetails,
                      )
                        ? otherUser(
                            contacts.find((element) => {
                              return (
                                element?.username === isActive ||
                                otherUser(element?.userDetails)?.username === isActive
                              );
                            })?.userDetails,
                          )?.profilePictureUrl ||
                          otherUser(
                            contacts.find((element) => {
                              return (
                                element?.username === isActive ||
                                otherUser(element?.userDetails)?.username === isActive
                              );
                            })?.userDetails,
                          )?.profilePicture
                        : contacts.find((element) => {
                            return (
                              element?.username === isActive ||
                              otherUser(element?.userDetails)?.username === isActive
                            );
                          })?.profilePictureUrl ||
                          contacts.find((element) => {
                            return (
                              element?.username === isActive ||
                              otherUser(element?.userDetails)?.username === isActive
                            );
                          })?.profilePicture
                    }
                    classNames="w-[52px] h-[52px] ml-4 md:ml-0 cursor-pointer"
                  />
                  <div className="ml-2">
                    <span className="text-[20px] font-semibold">
                      {selected?.lastMessage
                        ? truncateName(
                            otherUser(selected?.userDetails)?.firstName +
                              ' ' +
                              otherUser(selected?.userDetails)?.lastName,
                          )
                        : truncateName(selected?.firstName + ' ' + selected?.lastName)}
                    </span>
                  </div>
                </div>
                <div className="mr-[10px]">
                  <Dropdown
                    parentClassName="rounded-lg"
                    optionItemClassName="hover:bg-greylighter"
                    options={
                      selected?.lastMessage
                        ? contacts.find(
                            (element) => otherUser(element?.userDetails)?.username === isActive,
                          )?.blockUser?.blocked &&
                          contacts.find(
                            (element) => otherUser(element?.userDetails)?.username === isActive,
                          )?.blockUser?.blockedId === myProfile?.id
                          ? [
                              {
                                name: 'Delete Chat',
                                action: () => setIsDeleteModalOpen(true),
                              },
                              {
                                name: 'Report User',
                                action: () => setIsReportModalOpen(true),
                              },
                            ]
                          : [
                              {
                                name: 'Delete Chat',
                                action: () => setIsDeleteModalOpen(true),
                              },
                              {
                                name: 'Report User',
                                action: () => setIsReportModalOpen(true),
                              },
                              {
                                name: contacts.find(
                                  (element) =>
                                    otherUser(element?.userDetails)?.username === isActive,
                                )?.blockUser?.blocked
                                  ? 'Unblock'
                                  : 'Block',
                                action: () => setIsBlockModalOpen(true),
                              },
                            ]
                        : [
                            {
                              name: 'Delete Chat',
                              action: () => setIsDeleteModalOpen(true),
                            },
                            {
                              name: 'Report User',
                              action: () => setIsReportModalOpen(true),
                            },
                            {
                              name: 'Block',
                              action: () => setIsBlockModalOpen(true),
                            },
                          ]
                    }
                    IconComponent={() => <ThreeDots className="w-[4.8px] h-[24px] rotate-90" />}
                  />
                </div>
              </div>
              <div className=" overflow-auto scrollbar-custom h-full" ref={chatContainerRef}>
                <Chats
                  fileData={fileData}
                  retrievedDocumentId={retrievedDocumentId}
                  scrollChatContainer={scrollChatContainer}
                  selected={selected}
                  onCrossIconClick={handleCrossIconClick}
                  attachmentProgress={attachmentProgress}
                  contacts={contacts}
                  mediaLength={mediaLength}
                  isActive={isActive}
                />
              </div>
              {selected?.lastMessage ? (
                contacts.find((element) => otherUser(element?.userDetails)?.username === isActive)
                  ?.blockUser?.blocked ? (
                  contacts.find((element) => otherUser(element?.userDetails)?.username === isActive)
                    ?.blockUser?.blockedById === myProfile?.id ? (
                    <div className="mx-auto">
                      <p>You have blocked this user</p>
                    </div>
                  ) : (
                    <div className="mx-auto">
                      <p>You cant send message to this user</p>
                    </div>
                  )
                ) : (
                  <InputTextarea
                    onFileInputChange={handleFileInputChange}
                    toId={toId}
                    contacts={contacts}
                    isActive={isActive}
                    clearInput={clearInput}
                    onProgress={handleProgress}
                    onMediaLengthChange={handleMediaLengthChange}
                  />
                )
              ) : (
                <InputTextarea
                  onFileInputChange={handleFileInputChange}
                  toId={toId}
                  contacts={contacts}
                  isActive={isActive}
                  clearInput={clearInput}
                  onProgress={handleProgress}
                  onMediaLengthChange={handleMediaLengthChange}
                />
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <div className="w-fit text-center">
                <img src={noMessage} alt="" className="m-auto" />
                <span className="text-base font-semibold text-greydark">
                  Select a chat or start a new conversation
                </span>
              </div>
            </div>
          )}
        </div>
        <ConfirmationModal
          title="Delete Chat"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          primaryButtonTitle="No"
          primaryButtonAction={() => setIsDeleteModalOpen(false)}
          secondaryButtonTitle="Yes"
          secondaryButtonAction={() => {
            deleteChat();
          }}
        >
          <div className="w-[286px] mx-auto ">
            <span className="text-[18px] font-medium">Are you sure you want to delete chat?</span>
          </div>
        </ConfirmationModal>

        <ReportUser
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          userId={userId}
        />

        <ConfirmationModal
          title="Block User"
          isOpen={isBlockModalOpen}
          onClose={() => setIsBlockModalOpen(false)}
          primaryButtonTitle="No"
          primaryButtonAction={() => setIsBlockModalOpen(false)}
          secondaryButtonTitle="Yes"
          secondaryButtonAction={() => blockClickHandler()}
        >
          <div className="text-[18px] tx-greydark font-medium">
            {selected?.blockUser?.blocked
              ? 'Are you sure you want to unblock “'
              : 'Are you sure you want to block “'}
            {selected?.lastMessage
              ? truncateName(
                  otherUser(selected?.userDetails)?.firstName +
                    ' ' +
                    otherUser(selected?.userDetails)?.lastName,
                )
              : truncateName(selected?.firstName + ' ' + selected?.lastName)}
            ”?
          </div>
        </ConfirmationModal>
      </div>
    </SectionLayout>
  );
};

export default Messages;
