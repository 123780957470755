import { useEffect, useState } from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';
import Tabs from '../../components/common/Tabs';
import MyNetworkTabSection from './MyNetworkTabSection';
import { TABS_NAME } from '../../constants/lang';
import SectionLayout from '../../components/PrivateLayout/SectionLayout';
import { useSearchParams } from 'react-router-dom';
import {
  fetchMyConnectionsDispatcher,
  fetchMyFollowersDispatcher,
  fetchMyFollowingsDispatcher,
} from '../../redux/dispatchers/myNetworkDispatcher';
import { useDispatch } from 'react-redux';
const { FOLLOWERS, FOLLOWING, CONNECTIONS } = TABS_NAME;

const MyNetwork = () => {
  const [selectedTab, setSelectedTab] = useState(FOLLOWERS);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [connectionCount, setConnectionCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  let tabValue = searchParams?.get('type');

  const removeQueryParam = (paramKey) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(paramKey);
    setSearchParams(newSearchParams);
  };

  const handleRemoveQueryParam = () => {
    // We will remove type from the URL when the page is loaded
    removeQueryParam('type');
  };

  useEffect(() => {
    if (tabValue) {
      setSelectedTab(tabValue);
      handleRemoveQueryParam();
    }
  }, [tabValue]);

  // Scrolling to top whenever user comes on this page for the first time
  useScrollToTop();

  const handleSetTab = (selectedTab) => {
    setSelectedTab(selectedTab?.value);
  };

  const getCounts = async () => {
    const page = 1;
    const pageSize = 10;
    const searchVal = '';

    const followersResponse = await dispatch(
      fetchMyFollowersDispatcher({ page, limit: pageSize, search: searchVal }),
    );
    setFollowersCount(followersResponse?.data?.count);

    const followingResponse = await dispatch(
      fetchMyFollowingsDispatcher({ page, limit: pageSize, search: searchVal }),
    );
    setFollowingCount(followingResponse?.data?.count);

    const connectionResponse = await dispatch(
      fetchMyConnectionsDispatcher({ page, limit: pageSize, search: searchVal }),
    );
    setConnectionCount(connectionResponse?.data?.count);
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <SectionLayout activeTab={1}>
      <div className="col-span-10 xs:col-span-12 sm:col-span-12 lg:col-span-8 md:col-span-12 xl:col-span-9 overflow-y-auto lg:mb-6 lg:mt-4">
        <div className="grid grid-cols-12 gap-3 feed-page">
          <div className="col-span-12">
            <div>
              <Tabs
                handleSetTab={handleSetTab}
                selectedTab={tabValue || selectedTab}
                tabs={[
                  { name: `Followers (${followersCount})`, value: FOLLOWERS },
                  { name: `Following (${followingCount})`, value: FOLLOWING },
                  { name: `Connections (${connectionCount})`, value: CONNECTIONS },
                ]}
              />
              <MyNetworkTabSection getCounts={getCounts} selectedTab={tabValue || selectedTab} />
            </div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default MyNetwork;
