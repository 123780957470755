/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import MultiSelectBox from '../common/MultiSelect';
import AutoComplete from '../common/AutoComplete';
import SelectBox from '../common/SelectBox';
import { Button } from '../common/Button';
import addCircle from '../../assets/images/add_circle.svg';
import {
  addMoreAboutYou,
  getLanguageListing,
  getMySelfDetails,
  getSoftSkillsListing,
} from '../../services/myself';
import { getErrorMessage, successStatus } from '../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../Toast/ToastNotify';
import { LIMITS } from '../../constants/constants';

const genderOptions = [
  'Male',
  'Female',
  'Transgender female',
  'Transgender male',
  'Non-conforming',
  'Non-binary',
  'Prefer not to answer',
];
const pronounOptions = ['She/Her', 'He/Him', 'They/Them'];
const proficiencyOptions = [
  'Elementary proficiency',
  'Limited working proficiency',
  'Professional working proficiency',
  'Full professional proficiency',
  'Primary fluency / Bilingual proficiency',
];

const MoreAboutYou = ({
  hideLocationHeading = false,
  childClassPadding = '',
  parentWrapClass = '',
  setIsSaveClicked = () => {},
  getMyselfData = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  //optionsListingValues
  const [softSkillsOptions, setSoftSkillsOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);

  //dataValues
  const [softSkillsData, setSoftSkillsData] = useState([]);
  const [pronounValue, setPronounValue] = useState('');
  const [genderValue, setGenderValue] = useState('');
  const [removeLanguagesId, setRemoveLanguagesId] = useState([]);
  const [languagesData, setLanguagesData] = useState([]);

  const getListingValues = async () => {
    const response = await getSoftSkillsListing();
    const { data: { data = [] } = {}, status = '' } = response;
    if (status) {
      const refractedData = data.map(({ title = '' }) => {
        return title;
      });
      setSoftSkillsOptions(refractedData);
    }
    const langResponse = await getLanguageListing();
    const { data: { data: langData = [] } = {}, status: langStatus = '' } = langResponse;
    if (langStatus) {
      const refractedData = langData.map(({ title = '' }) => {
        return title;
      });
      setLanguageOptions(refractedData);
    }
  };

  const getMySelfDetailsData = async () => {
    const response = await getMySelfDetails();
    const { data: { data = '' } = {}, status = '' } = response;
    if (status) {
      const {
        genderIdentity = '',
        languageAndProficiency = [],
        softSkills = [],
        pronoun = [],
      } = data;
      const refractedLanguageData = languageAndProficiency.map((item) => {
        return {
          language: item.language,
          id: item.id,
          proficiency: item.proficiency,
        };
      });
      setGenderValue(genderIdentity[0]);
      setLanguagesData(refractedLanguageData);
      setSoftSkillsData(softSkills);
      setPronounValue(pronoun[0]);
      addLanguageData(refractedLanguageData);
    }
  };

  useEffect(() => {
    getListingValues();
    getMySelfDetailsData();
  }, []);

  const addLanguageData = (fetchedData) => {
    const data = {
      keyId: Math.random(),
      language: '',
      proficiency: proficiencyOptions[0],
    };
    const tempData = JSON.parse(JSON.stringify([...fetchedData]));
    tempData.push(data);
    setLanguagesData(tempData);
  };

  const constructRequestPayload = (data, keyName, value) => {
    if (value) {
      data[keyName] = [value];
      return data;
    } else {
      data[keyName] = [];
      return data;
    }
  };

  const handleSubmitData = async () => {
    const refractedLanguageData = languagesData.map(({ keyId, ...rest }) => rest);
    const removedEmptyLnaguageData = refractedLanguageData.filter((item) => item.language);
    const updatedData = [...removedEmptyLnaguageData, ...removeLanguagesId];
    let requestData = {
      softSkills: softSkillsData,
      languageAndProficiency: updatedData,
    };
    requestData = constructRequestPayload(requestData, 'pronoun', pronounValue);
    requestData = constructRequestPayload(requestData, 'genderIdentity', genderValue);
    if (!isLoading) {
      setIsLoading(true);
      const response = await addMoreAboutYou(requestData);
      const { status = '', data = '' } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        getMySelfDetailsData();
        getMyselfData();
        ToastNotifySuccess('More About You saved successfully', 'location-success');
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, 'location-failed');
        }
      }
      setIsSaveClicked(true);
      setIsLoading(false);
    }
  };

  const handleLanguageChange = (e, index, name) => {
    const tempData = JSON.parse(JSON.stringify([...languagesData]));
    const updatedLanguagesData = tempData.map((item, itemIndex) => {
      if (itemIndex === index) {
        item[name] = e;
      }
      return item;
    });
    setLanguagesData(updatedLanguagesData);
  };

  const removeLanguageData = (index) => {
    const tempData = JSON.parse(JSON.stringify([...languagesData]));
    const updatedLanguagesData = tempData.filter((item, itemIndex) => {
      if (itemIndex === index) {
        if (item?.id) {
          let data = {
            id: item.id,
          };
          const prevData = [...removeLanguagesId];
          prevData.push(data);
          setRemoveLanguagesId([...prevData]);
        }
      } else {
        return item;
      }
    });
    setLanguagesData(updatedLanguagesData);
  };

  return (
    <div className={`rounded-[4px] bg-white shadow-md rounded-b-[0] ${parentWrapClass}`}>
      {!hideLocationHeading ? (
        <div className="p-[18px] bg-[#E9F6FF] rounded-[4px]">
          <div className="text-[#0071BC] text-[14px] font-semibold">More About you</div>
        </div>
      ) : (
        ''
      )}
      <div>
        <div className={`py-[36px] px-[32px] pb-2 ${childClassPadding}`}>
          <div
            style={{
              border: '1px solid yellow',
              borderRadius: '8px',
              padding: '3px 15px',
              marginBottom: '15px',
              background: 'lightYellow',
            }}
          >
            Note: Please press enter to add skills
          </div>
          <div className="flex pb-6 items-center sm:flex-row flex-col">
            <div className="text-[#333] sm:text-[20px] text-[14px] font-medium normal- case sm:w-[15vw] sm:max-w-[260px] sm:min-w-[150px] w-full">
              Soft Skills
            </div>
            <div className="lg:w-[26.5vw] lg:max-w-[400px] w-full">
              <MultiSelectBox
                options={softSkillsOptions}
                placeholder="Add / Select Soft Skills"
                selectedData={softSkillsData}
                setSelectedData={setSoftSkillsData}
                maxLength={LIMITS.MAX_LENGTH_SOFTSKILLS}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-[24px] pb-6">
            {languagesData.map(
              ({ language = '', proficiency = '', keyId = '', id = '' }, index) => {
                return (
                  <div
                    key={id ? id : keyId}
                    className="flex flex-wrap gap-y-[20px] xl:gap-x-[2vw] gap-x-[0.5vw]"
                  >
                    <div className="flex mr-[1vw] w-full lg:w-auto sm:flex-row flex-col sm:items-center">
                      <div className="text-[#333] sm:text-[20px] text-[14px] font-medium normal- case w-[15vw] max-w-[260px] min-w-[150px]">
                        Language
                      </div>
                      <div className="lg:w-[26.5vw] lg:max-w-[400px] w-full">
                        <AutoComplete
                          options={languageOptions}
                          onChange={(e) => handleLanguageChange(e, index, 'language')}
                          defaultValue={language}
                          placeholder="Add / Select Language"
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full lg:w-auto sm:flex-row flex-col">
                      <div className="text-[#333] sm:text-[20px] text-[14px] font-medium normal-case sm:w-[15vw] sm:max-w-[260px] lg:min-w-[120px] sm:min-w-[150px] w-full">
                        Proficiency
                      </div>
                      <div className="flex items-center lg:w-[25vw] lg:max-w-[400px] w-full">
                        <SelectBox
                          options={proficiencyOptions}
                          onChange={(e) => handleLanguageChange(e, index, 'proficiency')}
                          defaultValue={proficiency}
                        />
                        {index === languagesData.length - 1 ? (
                          <img
                            src={addCircle}
                            alt=""
                            className="cursor-pointer ml-[15px]"
                            onClick={() => addLanguageData(languagesData)}
                          />
                        ) : (
                          <img
                            src={addCircle}
                            alt=""
                            className="cursor-pointer rotate-45 ml-[15px]"
                            onClick={() => removeLanguageData(index)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
          <div className="flex pb-6 items-center sm:flex-row flex-col">
            <div className="text-[#333] sm:text-[20px] text-[14px] font-medium normal- case sm:w-[15vw] sm:max-w-[260px] sm:min-w-[150px] w-full">
              Pronoun
            </div>
            <div className="lg:w-[26.5vw] lg:max-w-[400px] w-full">
              <AutoComplete
                options={pronounOptions}
                onChange={(e) => setPronounValue(e)}
                defaultValue={pronounValue}
                placeholder="Select"
              />
            </div>
          </div>
          <div className="flex pb-6 items-center sm:flex-row flex-col">
            <div className="text-[#333] sm:text-[20px] text-[14px] font-medium normal- case sm:w-[15vw] sm:max-w-[260px] sm:min-w-[150px] w-full">
              Gender Identity
            </div>
            <div className="lg:w-[26.5vw] lg:max-w-[400px] w-full">
              <AutoComplete
                options={genderOptions}
                onChange={(e) => setGenderValue(e)}
                defaultValue={genderValue}
                placeholder="Select"
              />
            </div>
          </div>
        </div>
        <hr className="pb-8" style={{ color: 'rgba(161, 160, 160, 0.50)' }} />
        <div className="grid justify-items-end pb-8 px-[2.2vw]">
          <Button
            label="Save"
            showArrowIcon={false}
            onlyShowLoaderWhenLoading={true}
            isLoading={isLoading}
            onClick={handleSubmitData}
          />
        </div>
      </div>
    </div>
  );
};

export default MoreAboutYou;
