import React from 'react';
import AssesmentsLayout from '../../../components/MyselfPage/AssesmentLayout';
import { Button } from '../../../components/common/Button';
import RadioButtonsGroup from '../../../components/common/RadioButtonsGroup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { addAssesmentAttributes, getAssesmentAttributes } from '../../../services/myself';
import { getErrorMessage, successStatus } from '../../../common';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { useSelector } from 'react-redux';

const options = [
  {
    label: 'High',
    value: 'high',
    name: 'High',
  },
  {
    label: 'Medium',
    value: 'medium',
    name: 'Medium',
  },
  {
    label: 'Low',
    value: 'low',
    name: 'Low',
  },
];

const pageData = [
  {
    title: 'Grit',
    description:
      'Every day can bring frustration, aggravation, disruption, and disappointment, and those with more grit will be better able to push through them.',
    categories: [
      {
        key: 'courage',
        name: 'Courage',
      },
      {
        key: 'perseverance',
        name: 'Perseverance',
      },
      {
        key: 'adaptability',
        name: 'Adaptability',
      },
      {
        key: 'resilience',
        name: 'Resilience',
      },
    ],
  },
  {
    title: 'Mental acuity',
    description:
      'Mental acuity is basically a measure of how sharp the mind is. It has little to do with education, or even raw intelligence; there’s no concern with how well-read or quick-witted you are. Rather, we’re focusing on the ability to effectively absorb and understand information, to concentrate, focus, and remember.',
    categories: [
      {
        key: 'learnability',
        name: 'Learnability',
      },
      {
        key: 'taskSwitching',
        name: 'Task switching',
      },
      {
        key: 'compartmentalization',
        name: 'Compartmentalization',
      },
      {
        key: 'situationalAwareness',
        name: 'Situational awareness',
      },
    ],
  },
  {
    title: 'Drive',
    description:
      'We all know people who are extremely driven and others who can’t ever seem to get started. We probably also know people who are fast off the starting line but never finish, while some others are unstoppable only after they get an initial push. The difference, at least some of it, can be found in these attributes.',
    categories: [
      {
        key: 'cunning',
        name: 'Cunning',
      },
      {
        key: 'discipline',
        name: 'Discipline',
      },
      {
        key: 'narcissism',
        name: 'Narcissism',
      },
      {
        key: 'openMindedness',
        name: 'Open-Mindedness',
      },
    ],
  },
  {
    title: 'Leadership',
    description:
      'Leadership is the ability to influence, motivate, and guide individuals or groups towards the achievement of a common goal. Good leaders also have the ability to empower and inspire their team to perform at their best, while promoting a positive and productive work environment.',
    categories: [
      {
        key: 'empathy',
        name: 'Empathy',
      },
      {
        key: 'authenticity',
        name: 'Authenticity',
      },
      {
        key: 'selflessness',
        name: 'Selflessness',
      },
      {
        key: 'decisiveness',
        name: 'Decisiveness',
      },
      {
        key: 'accountability',
        name: 'Accountability',
      },
    ],
  },
  {
    title: 'Team ability',
    description:
      'Team ability refers to the ability of individuals to work effectively and efficiently as part of a team. A person with strong team ability skills is able to work well with others, effectively resolve conflicts, and support their team members in order to achieve common objectives.',
    categories: [
      {
        key: 'humor',
        name: 'Humor',
      },
      {
        key: 'humility',
        name: 'Humility',
      },
      {
        key: 'integrity',
        name: 'Integrity',
      },
      {
        key: 'conscientiousness',
        name: 'Conscientiousness',
      },
    ],
  },
];

const initialData = {
  courage: null,
  perseverance: null,
  adaptability: null,
  resilience: null,
  learnability: null,
  taskSwitching: null,
  compartmentalization: null,
  situationalAwareness: null,
  cunning: null,
  discipline: null,
  narcissism: null,
  openMindedness: null,
  empathy: null,
  authenticity: null,
  selflessness: null,
  decisiveness: null,
  accountability: null,
  humor: null,
  humility: null,
  integrity: null,
  conscientiousness: null,
};

const Attributes = () => {
  const { isOriginSignup = false } = useSelector((state) => state.auth || {});

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const getAttributesData = async () => {
    const response = await getAssesmentAttributes();
    const { data: { data = '' } = {}, status = '' } = response;
    if (status && data) {
      const { id, UserId, createdAt, updatedAt, ...rest } = data;
      formik.setValues(rest);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAttributesData();
  }, []);

  const submitHandler = async (values, notifyOthers = false) => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await addAssesmentAttributes({ ...values, notifyOthers });
      const { status = '', data = '' } = response;
      const errormsg = getErrorMessage(data);
      if (successStatus(status)) {
        getAssesmentAttributes();
        ToastNotifySuccess('Attributes saved successfully', 'location-success');
      } else {
        if (errormsg) {
          ToastNotifyError(errormsg, 'location-failed');
        }
      }
      setIsCreateModalOpen(false);
      setIsLoading(false);
      navigate(-1);
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: submitHandler,
  });

  const handleRadioSelect = (name, value) => {
    if (formik.values[name] === value) {
      formik.setFieldValue(name, null);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleSaveType = () => {
    if (isOriginSignup) {
      submitHandler(formik.values, false);
    } else if (Object.values(formik.values).some((value) => value !== null)) {
      setIsCreateModalOpen(true);
    } else {
      submitHandler(formik.values, false);
    }
  };

  return (
    <>
      <AssesmentsLayout title="The Attributes">
        <div className="rounded-md bg-white px-[24px] py-[36px]">
          <div className="text-[#0071BC] md:text-[24px] text-[18px] font-medium mb-[24px]">
            Attributes assessment
          </div>
          <div className="text-[#0071BC] md:text-[14px] text-[12px] font-medium leading-6 rounded-md bg-[#0071BC0D] p-1 w-fit md:px-[28px] px-[12px] md:py-[13px] py-[6px] mb-[40px]">
            Enter the result of your assessments by selecting the appropriate option for each one of
            them.
          </div>
          <div>
            <form autoComplete="off">
              {pageData.map((item, index) => {
                return (
                  <div key={index + item?.title}>
                    <div className="text-[#0071BC] sm:text-2xl text-[16px] font-bold mb-[10px]">
                      {item?.title}
                    </div>
                    <div className="text-[#333] text-[14px] font-normal mb-[35px]">
                      {item?.description}
                    </div>
                    <div>
                      {item?.categories?.map(({ key, name }, index) => {
                        return (
                          <>
                            <div
                              className="flex justify-between mb-[27px] md:flex-row flex-col gap-y-[10px]"
                              key={index + name}
                            >
                              <div className="text-[#333] text-base font-semibold">{name}</div>
                              <div className="md:pr-[5vw]">
                                <RadioButtonsGroup
                                  options={options}
                                  defaultValue={formik.values[key]}
                                  name={key}
                                  optionsClass="text-[16px] font-normal"
                                  onSelect={(fieldName, value) =>
                                    handleRadioSelect(fieldName, value)
                                  }
                                  className="flex flex-row md:gap-[4vw] justify-between md:w-auto w-full"
                                />
                              </div>
                            </div>
                            <hr
                              className="pb-8 hidden md:block"
                              style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </form>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col-reverse justify-end items-center sm:gap-[2vw] gap-y-[20px] sm:mt-[36px] mt-[0px]">
          <div
            className="text-[#333] text-base font-medium cursor-pointer sm:w-[auto] w-full text-center"
            onClick={() => navigate(-1)}
          >
            Cancel
          </div>
          <Button
            label="Save"
            onClick={() => {
              handleSaveType();
            }}
            showArrowIcon={false}
            customClass="sm:w-auto w-full"
          />
        </div>
      </AssesmentsLayout>
      <ConfirmationModal
        title="Notify others"
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        secondaryButtonTitle="No"
        secondaryButtonAction={() => submitHandler(formik.values, false)}
        primaryButtonTitle="Yes"
        primaryButtonAction={() => submitHandler(formik.values, true)}
        isPrimaryButtonDisabled={isLoading}
      >
        <div className="text-[18px] tx-greydark font-medium">
          Do you want to notify your connections about your profile update?
        </div>
      </ConfirmationModal>
    </>
  );
};

export default Attributes;
